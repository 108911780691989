<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
        {{ $t('forms.add_model', {model: $t('models.dreamer')})}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('fields.name')"
            rules="required"
          >
            <b-form-group
              :label="$t('fields.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="recordData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <!-- BIRTHDATE -->
          <validation-provider
            #default="validationContext"
            :name="$t('fields.f_nac')"
            rules="required"
          >
            <b-form-group
              :label="$t('fields.f_nac')"
              label-for="birthdate"
            >
              <b-form-datepicker
                id="birthdate"
                v-model="recordData.birthdate"
                autofocus
                :locale="getLocal"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- LINKED ACCOUNTS -->
          <b-form-group
            :label="$t('fields.student_account')"
            label-for="email"
          >
            <v-select
              v-model="recordData.linked_users"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="email"
              :options="users"
            />
          </b-form-group>

          <!-- Family Accounts -->
          <template v-for="(fam_acc, idx) in recordData.family_account">
          <validation-provider
            :key="idx"
            #default="validationContext"
            :name="$t('fields.family_account')"
            rules="email"
          >
            <b-form-group
              :label="`${$t('fields.family_account')} #${idx+1}`"
              :label-for="`family_account[${idx}]`"
            >
              <b-input-group>
              <b-form-input
                :id="`family_account[${idx}]`"
                v-model="recordData.family_account[idx].email"
                autofocus
                :state="recordData.family_account[idx].validated && getValidationState(validationContext)"
                trim
                placeholder=""
              />
              <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      style="color: tomato"
                      icon="TrashIcon"
                      @click="deleteFamAcc(idx)"
                    />
                  </b-input-group-append>
                </b-input-group>
                <span style="color:#ea5455; font-size: 0.857rem; width:100%;">
                  {{ !recordData.family_account[idx].validated ? $t('fields.family_account_error') : ''}}
                </span>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          </template>

          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="link"
              class="mb-1"
              @click="AddFamilyEmailField"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-75"
              />
              {{ $t('fields.add_family_email')}}

            </b-button>

          <!-- GROUP -->
          <b-form-group
            :label="$t('fields.group')"
            label-for="group"
          >
            <v-select
              v-model="recordData.linked_groups"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :options="groups"
            />
          </b-form-group>
          
          <!-- REG NUMB -->
          <validation-provider
            #default="validationContext"
            :name="$t('fields.registration_number')"
            rules=""
          >
            <b-form-group>
              <label for="registration_number" class="d-block">
                {{$t('fields.registration_number')}}
                <feather-icon
                  v-b-tooltip.hover.bottom="$t('fields.registration_number_info')"
                  icon="HelpCircleIcon"
                  size="16"
                  class="text-muted cursor-pointer ml-1"
                />
              </label>
              <b-form-input
                id="registration_number"
                v-model="recordData.registration_number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('forms.add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('forms.cancel')}}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend,VBTooltip,
  BSidebar,BMedia, BForm, BCol, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BInputGroup, BInputGroupAppend,
    BMedia,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker, 
    BButton,
    vSelect,
    BCol,
    VBTooltip,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  computed:{
    getLocal(){
      return this.$store.getters['user/getLang']
    },
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    school_id: {
      type: [String, Number],
      required: true,
    },
    groups:{
      type: Array,
      required: true,
    },
    users:{
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankrecordData = {
      name: '',
      avatar: 57,
      birthdate: '',
      linked_groups: '',
      linked_users: '',
      family_account:[],
    }

    const recordData = ref(JSON.parse(JSON.stringify(blankrecordData)))
    const resetrecordData = () => {
      recordData.value = JSON.parse(JSON.stringify(blankrecordData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetrecordData)


    return {
      recordData,

      // form validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods:{
    deleteFamAcc(idx){
      this.recordData.family_account.splice(idx, 1)
    },
    AddFamilyEmailField(){
      this.recordData.family_account.push({email:'', validated:true})
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    validated(){
      let validate = false
      let fam_acc = []
      if(this.recordData.family_account.length > 0){
        fam_acc = this.recordData.family_account.filter(element => element.email != "");
        /* this.recordData.family_account.forEach(element => {
          if(element.email == ""){
            validate = false
          }
        }); */
      }
      this.recordData.family_account = fam_acc
      console.log(this.recordData.family_account)
      return validate
    },
    async onSubmit(){
      let fam_acc = []
      if(this.recordData.family_account.length > 0){
        fam_acc = this.recordData.family_account.filter(element => element.email != "");
      }
      this.recordData.family_account = fam_acc
      let data = this.recordData
      data['school_id'] = this.school_id
      console.log(data)
      await this.$http.post('school/dreamer/create', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
        this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-sidebar-active', false)
        } else if(response.data.status == 204){
          this.makeToast('danger', this.$t('Error'), 'Please check the family accounts indicated');
          console.log(response.data)
          this.recordData.family_account = response.data.family_account
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })
    
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
