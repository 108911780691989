<template>
  <b-modal id="bv-modal-massive" hide-footer>
      <template #modal-title>
        Carga Masiva con Excel
      </template>
      <div class="d-block text-center">
        <b-form @submit.prevent>
            <p>Dispone de un <b> ejemplo de plantilla </b> para realizar la carga de {{ from }}</p>
            <b-row>
                <b-col cols="12">
                    <b-form-file
                        v-model="file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    />
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="button"
                        variant="primary"
                        class="mr-1"
                    >{{ $t('forms.upload') }}
                    </b-button>
                    <b-button
                        @click="$bvModal.hide('bv-modal-massive')"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="button"
                        variant="outline-secondary"
                    >{{ $t('forms.cancel') }}
                    </b-button>
                </b-col>
            </b-row>


      </b-form>
      </div>
    </b-modal>
</template>

<script>
import {
  BCard, BCardText, BTable,
  BFormDatepicker,
  BButton, BRow, BCol, 
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BAvatar, BAvatarGroup, VBTooltip,
  BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        flatPickr, 
        BCard, BCardText, 
        BFormDatepicker,
        BTable,
        BButton, BRow, BCol, 
        BFormCheckbox,
        BFormGroup, BFormInput, BForm, BFormRadio,
        BAvatar, BAvatarGroup,VBTooltip,
        BFormFile,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    props: {
        from: String,
    },
    data(){
      return {
      
        file: null,
        
      }
  }
}
</script>

<style lang="scss" scoped>

</style>