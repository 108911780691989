<template>
  <section id="dashboard-analytics" >
    <dreamers-tic v-if="userData.role == 'school-admin'" />
    <dreamers-general v-else />
  </section>
</template>

<script>
import DreamersTic from './DreamersTIC.vue'
import DreamersGeneral from './DreamersGeneral.vue'
export default {
  components:{
    DreamersTic,
    DreamersGeneral

},
  data(){
    return {
      userData: this.$store.getters['user/getUserData']
    }
  }
}
</script>

<style>

</style>