<template>
  <div>
      <b-card-body >
        <b-row>
          <b-col
            cols="6"
          >
            <b-form-checkbox
              v-if="canSelect && data.length>0"
              v-model="allSelected"
              @change="manageSelectAll(allSelected)"
              inline
              :disabled="checkboxIsDisabled"
            >
              {{ $t('table.select_all_rows')}}
            </b-form-checkbox>
          </b-col>
          <b-col cols="6" style="text-align:right">
            <b-button
              v-if="canDownload"
              v-b-tooltip.hover.top="$t('table.export_to_excel')"
              variant="primary"
              type="button"
              class="mr-1 mb-1"
              @click="download"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <svg  width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#fff" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"/></svg>
            </b-button>
            <!-- buttonsOptions -->
            <template v-for="button in buttonsOptions">
              <b-button
                v-if="!button.dropdown"
                :key="button.action"
                v-b-tooltip.hover.top="button.name"
                :variant="button.variant||'primary'"
                type="button"
                class="mr-1 mb-1"
                @click="$emit(button.action)"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
              <feather-icon
                :icon="button.icon"
                size="16"
              />
              </b-button>
              <!-- dropdown -->
              <b-dropdown
                v-else
                right
                class="mb-1"
                :key="button.action"
                :id="button.action"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="button.variant"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                v-b-tooltip.hover.top="button.name"
              >
                <template #button-content>
                  <feather-icon
                    :icon="button.icon"
                    size="16"
                    class="align-middle"
                  />
                </template>
                <b-dropdown-item v-for="item in button.dropdown"
                  :key="item.action" 
                  @click="$emit(item.action)"
                  :variant="item.variant"
                >
                  <feather-icon
                    :icon="item.icon"
                    size="16"
                  />
                  <span class="align-middle ml-50">{{ item.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <!-- fin dropdown -->
            
          </b-col>
        </b-row>
      </b-card-body>

      <!-- TABLE  -->
      <b-skeleton-table
        v-if="loading_data"
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
      <b-table v-else
        :per-page="perPage"
        :current-page="currentPage"
        ref="refUserListTable"
        class="position-relative"
        :items="data"
        responsive
        :fields="tableColumns"
        sort-icon-left
        primary-key="id"
        show-empty
        :empty-text="fetch_message || $t('table.empty') "
        striped
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
        no-select-on-click
      >

        <!-- Column: MAIN -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-form-checkbox
                v-if="!(link=='school-groups' && data.item.id == 0) && canSelect && !data.item.deleted_at"
                class="mr-2"
                v-model="data.rowSelected"
                :disabled="data.item.deleted_at? true:false"
                @change="manageRowSelection(data.index, data.rowSelected)"
              ></b-form-checkbox>
              <div v-else class="mr-4"></div>
              <b-avatar
                rounded
                size="32"
                :src="resolveAvatar(data.item)"
                :text="avatarText(data.item.name || data.item.description)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: link+'-view', params: link_params(data) }"
              />
            </template>
            <span ></span>
            <b-link v-if="link=='school-groups' && data.item.id == 0"
              :to="{ name: data.item.id == 0?link+'-view-no-group':link+'-view', params: link_params(data) }"
              class="font-weight-bold d-block text-nowrap"
            >
             {{ $t('table.no_group')}}
            </b-link>
            <b-link v-else
              :to="{ name: link+'-view', params: link_params(data) }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name || data.item.description }}
            </b-link>
            <small v-if="!(link=='school-groups' && data.item.id == 0)" 
            class="text-muted">#{{ data.item.id }}</small>
          </b-media>
        </template>

        <!-- Column: Dreamers -->
        <template #cell(dreamers)="data">
          <b-avatar-group
              class="mt-2 pt-50 mb-1"
              size="33"
          >
            <b-avatar
              v-for="dreamer in data.item.dreamers.dreamers"
              :key="dreamer.id"
              v-b-tooltip.hover.bottom="dreamer.name"
              :src="require('@/assets/images/avatar/AvatarSprite_' + (dreamer.avatar || 57) + '.png?v4')"
              class="pull-up"
              variant="light"
              :to="{ name: 'school-dreamers-view', params: { 
                id: school_id,
                dreamer_id: dreamer.id 
              } }"

            />
            <h6 class="align-self-center cursor-pointer ml-1 mb-0" v-if="data.item.dreamers.total_dreamer > 0">
              {{ data.item.dreamers.total_dreamer - 5 >0 ? '+'+(data.item.dreamers.total_dreamer - 5): ''}}
            </h6>
            <span v-else class="text-muted"> {{$t('table.no_dreamers')}} </span>
          </b-avatar-group>
        </template>

         <!-- Column: groups -->
        <template #cell(groups)="data">
          <b-avatar-group
              class="mt-2 pt-50 mb-1"
              size="33"
          >
            <b-avatar
              rounded
              v-for="group in data.item.groups.groups"
              :key="group.id"
              v-b-tooltip.hover.bottom="group.name"
              :src="require('@/assets/images/avatar/groups/' + (group.id_avatar || 'null') + '.png?v4')"
              class="pull-up"
              variant="light"
              :to="{ name: 'school-groups-view', params: { 
                id: school_id,
                group_id: group.id 
              } }"
            />
            <h6 class="align-self-center cursor-pointer ml-1 mb-0" v-if="data.item.groups.total_groups > 0">
              {{ data.item.groups.total_groups - 5 >0 ? '+'+(data.item.groups.total_groups - 5): ''}}
            </h6>              
            

            <b-avatar v-else
              rounded
              key="0"
              v-b-tooltip.hover.bottom="$t('table.no_group')"
              :src="require('@/assets/images/avatar/groups/null.png?v4')"
              class="pull-up"
              variant="light"
              :to="{ name: 'school-groups-view-no-group', 
                params: params = { 
                  id: school_id,
                  group_id: 0 
                }
              }"
            />

          </b-avatar-group>
        </template>

        <!-- Column: birthdate -->
        <template #cell(birthdate)="data">
          <div class="text-nowrap">
            <span>{{ new Date(data.item.birthdate).toLocaleDateString() }}</span>
          </div>
        </template>

        <!-- Column: Created_at -->
        <template #cell(created_at)="data">
          <div class="text-nowrap">
            <span v-if="!(link=='school-groups' && data.item.id == 0)">{{ new Date(data.item.created_at).toLocaleDateString() }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${data.item.deleted_at?'danger':'success'}`"
            class="text-capitalize"
          >
            {{ data.item.deleted_at ? $t('fields.inactive') : $t('fields.active') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="!(link=='school-groups' && data.item.id == 0)"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: link+'-view', params: link_params(data) }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{$t('table.details')}}</span>
            </b-dropdown-item>

            <template v-if="$can('update', ability)">
              <b-dropdown-item v-if="!data.item.deleted_at" :to="{ name: link+'-edit', params: link_params(data) }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{$t('forms.edit')}}</span>
              </b-dropdown-item>
            </template>

            <template v-if="canDelete && $can('delete', ability)">
              <b-dropdown-item v-if="!data.item.deleted_at" @click="$emit('delete', data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('forms.delete')}}</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="canRecover && data.item.deleted_at" @click="$emit('recover', data.item.id)">
                <feather-icon icon="CornerUpLeftIcon" />
                <span class="align-middle ml-50">{{$t('forms.recover')}}</span>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>
      </b-table>
      
      <!-- Table Footer -->
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0" v-if="data.length>0">
          <b-form-group
            :label="$t('table.items_shown_per_page')"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              class="ml-1"
              v-model="perPage"
              size="sm"
              inline
              :options="perPageOptions"
            />
          </b-form-group>
          <div>
            <span class="text-muted">{{ $t('table.showing_entries', {
                from: dataMeta.from,
                to: dataMeta.to, 
                of: dataMeta.of
              })}}
            </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="data.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
      </b-card-body>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

import {
  BAvatarGroup, BCard, BCardBody, BRow, BCol, BFormGroup,  BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BFormCheckbox, BFormSelect,VBTooltip,  BCardHeader, BCardTitle, BBadge, BDropdown, BDropdownItem, BPagination, BSkeletonTable
} from 'bootstrap-vue'
export default {
  name: "TableList",
  components: {
    BAvatarGroup, BCard, BCardBody, VBTooltip, BRow, BCol, BFormGroup,  BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BFormCheckbox, BFormSelect, BCardHeader, BCardTitle, BBadge, BDropdown, BDropdownItem, BPagination, BSkeletonTable
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  setup(){
      return {
          avatarText
      }
  },
  props:{
    data:{
      type: Array,
      required:true,
    },
    ability:{
      type: String,
      required:true,
    },
    link:{
      type: String,
      required:true,
    },
    canDownload:{
      type: Boolean,
      required: false,
      default:false,
    },
    canDelete:{
      type: Boolean,
      required: false,
      default:true,
    },
    canRecover:{
      type: Boolean,
      required: false,
      default:true,
    },
    canSelect:{
      type: Boolean,
      required: false,
      default:true,
    },
    loading_data:{
      type: Boolean,
      required: false,
      default: false
    },
    fetch_message:{
      type: String,
      required: false,
      default: '',
    },
    school_id:{
      type: [Number, String],
      required: false,
      default: ''
    },
    buttonsOptions:{
      type: Array,
      required: false
    }
  },
  data(){
        return {
            perPageOptions: [
              { value: 10, text: '10' },
              { value: 25, text: '25' },
              { value: 50, text: '50' },
              { value: 100, text: '100' },
            ],
            perPage: 10,
            currentPage: 1,
            
            //users_length: null, 
            
            
            selected: [],
            allSelected: false,
            checkboxIsDisabled: false
        }
  },
  watch:{
    data(){
      var countActives = this.data.filter(function(element){
        return !element.deleted_at;
      }).length;
        if(countActives==0){
          this.checkboxIsDisabled = true
        } else {
          this.checkboxIsDisabled = false
        }
      this.allSelected = false
    },
    currentPage(){
      this.allSelected = false
    },
    selected(){
      var countActives = this.data.filter(function(element){
        return !element.deleted_at;
      }).length;

      if(this.perPage>countActives){
       
        if(this.selected.length == countActives){
          this.allSelected = true
        } else {
          this.allSelected = false
        }
      } else {
        if(this.selected.length == this.perPage){
          this.allSelected = true
        } else {
          this.allSelected = false
        }
      }
      this.$emit('manage-selected-rows', this.selected)
    }
  },
  computed: {
    tableColumns(){
      let tc = []
      switch (this.link) {
        case 'school':
          tc = [
            { key: 'name', label:this.$t('fields.school'),  sortable: true },
            { key: 'country', label:this.$t('fields.country'),sortable: true },
            { key: 'community', label:this.$t('fields.community'),sortable: true },
            { key: 'payment_method_id', label:this.$t('fields.payment_method_id'),sortable: true },
            { key: 'actions', label:this.$t('fields.action') },
          ]
          break;
        case 'partners':
          tc = [
            { key: 'name', label:'Partner',  sortable: true },
            { key: 'total_licenses', label:this.$t('dash.cards.total_licences'), sortable: true },
            { key: 'support_email', label:this.$t('fields.support_email'), sortable: true },
            { key: 'actions', label:this.$t('fields.action') },
          ]
          break;
        case 'school-groups':
          tc = [
            { key:'name', label:this.$t('fields.group'),  sortable: true },
            { key:'dreamers', label:this.$t('Dreamers'),  sortable: false },
            { key:'created_at', label:this.$t('fields.created_at'), sortable: true },
            { key: 'actions', label:this.$t('fields.action') },
          ]
          break;
        case 'school-dreamers':
          tc = [
            { key:'name', label:this.$t('models.dreamer'),  sortable: true },
            { key:'age', label:this.$t('fields.age'), sortable: true },
            { key:'birthdate', label:this.$t('fields.f_nac'), sortable: false },
            { key:'registration_number', label:this.$t('fields.registration_number') },
            { key:'groups', label:this.$t('fields.group'), sortable: false },
            { key:'created_at', label:this.$t('fields.created_at'), sortable: true },
            /* { key: 'status', label:this.$t('fields.status'), sortable: false }, */
            { key: 'actions', label:this.$t('fields.action') },
          ]
          break;
        case 'students':
          tc = [
            { key:'name', label:this.$t('fields.student'),  sortable: true },
            { key: 'email', label:this.$t('fields.email'),sortable: true },
            { key: 'library_version_code', label:this.$t('dash.cards.version'), sortable: false },
            { key: 'status', label:this.$t('fields.status'), sortable: false },
            { key: 'actions', label:this.$t('fields.action') },
          ]
          break;
        default:
          tc = [
            { key:'name', label:this.link,  sortable: true },
            { key: 'email', label:this.$t('fields.email'),sortable: true },
            { key: 'library_version_code', label:this.$t('dash.cards.version'), sortable: false },
            { key: 'role', label:this.$t('fields.role'),sortable: false },
            { key: 'status', label:this.$t('fields.status'), sortable: false },
            { key: 'actions', label:this.$t('fields.action') },
          ]
          break;
      }
      return tc
    },
    dataMeta(){
      let total = this.data.length
      let ini = total >= 1 ? 1 : 0;
      let res = (total % this.perPage)
      let total_pages = Math.floor(total / this.perPage) +1

      let localItemsCount = this.perPage;
      if(this.currentPage == total_pages){
          localItemsCount = this.perPage + res
      }
      return {
          from: this.perPage * (this.currentPage - 1) + (ini ? 1 : 0),
          to: this.currentPage == total_pages ? total : this.perPage * (this.currentPage - 1) + (ini ? localItemsCount:0),
          of: total,
      }
    },
  },
  methods: {
    async download(){
      let column_headers = []
      column_headers.push('id')
      this.tableColumns.forEach(element => {
        element.key != 'actions' ? column_headers.push(element.key) : null
      });
      let rows_ids = []
      this.data.map(x => {
        rows_ids.push(x.id)
      })
      let data = {
        rows_ids : rows_ids,
        context : this.link,
      }
      let route = "export/general"
      
      await this.$http.post(route, data, {responseType:'blob'}).then( response => {
        console.log("respuesta : ", response)
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.link+'.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        console.log("Err -> ", error);
      })
      
    },
    resolveAvatar(item){
      let avatar = ''
      switch (this.link) {
        case 'partners':
        case 'school':
          avatar = item.logo_url
          break;
        case 'school-groups':
          if (item.id==0) {
            avatar = require('@/assets/images/avatar/groups/null.png?v4')          
          } else{
            avatar = require('@/assets/images/avatar/groups/'+(item.id_avatar||'null')+'.png?v4')
          }
          break;
        case 'school-dreamers':
          avatar = require('@/assets/images/avatar/AvatarSprite_'+(item.avatar||'57')+'.png?v4')
          break;
      
        default:
          avatar = item.avatar
          break;
      }
      return avatar
    },
    link_params(data){
      let params = {}
      switch(this.link){
        case 'school-groups':
          params = { 
            id: this.school_id,
            group_id: data.item.id 
          }
          break;
        case 'school-dreamers':
          params = { 
            id: this.school_id,
            dreamer_id: data.item.id 
          }
          break;
        case 'students':
          params = { 
            id: this.school_id,
            student_id: data.item.id 
          }
          break;
        case 'school-teachers':
          params = { 
            id: this.school_id,
            teacher_id: data.item.id 
          }
          break;
        default:
          params = { id: data.item.id }
          break;
      }
      return params
    },
    manageSelectAll(val){
      if (val) {
        this.data.forEach((element, i) => {
          if(!element.deleted_at){
            this.$refs.refUserListTable.selectRow(i)
          }
        });
      } else {
        this.$refs.refUserListTable.clearSelected()
      }
    },
    manageRowSelection(index, value){
      if(value){
        this.$refs.refUserListTable.selectRow(index)
      } else {
        this.$refs.refUserListTable.unselectRow(index)
      }
    },
    onRowSelected(items) {
      this.selected = items //Array de objetos seleccionados
    },
  }
}
</script>

<style>

</style>