<template>
  <div>
      <user-list-add-new-dreamer
      :is-add-new-user-sidebar-active.sync="isAddNewDreamerSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <modal-personalize></modal-personalize>
    <modal-content-restriction></modal-content-restriction>
    <modal-children-passwords></modal-children-passwords>
    <modal-move-group></modal-move-group>
    <modal-massive
      from="dreamers"
    ></modal-massive>

    <!-- content-header -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="9"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $t('Dreamers')}}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item 
                :active="false">
                  <feather-icon
                    icon="SquareIcon"
                    size="18"
                    class="align-text-top"
                    style="margin-right: 5px;"
                  />
                  Select All
                  <!-- <font-awesome-icon class="fa-spin" icon="spinner" /> -->
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block  mb-1"
        md="3"
        cols="3"
      >
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >

          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
                  <b-dropdown-item 
                  @click="isAddNewDreamerSidebarActive = true"
                  >
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Añadir Niños</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />

                  <b-dropdown-item>
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50"
                      @click="$bvModal.show('bv-modal-personalize')">
                          Personalizar
                      </span>
                  </b-dropdown-item>
                  
                  
                  <b-dropdown-item @click="$bvModal.show('bv-modal-content-restriction')">
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Restringir Contenido</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="$bvModal.show('bv-modal-children-passwords')">
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Gestionar Contraseñas</span>
                  </b-dropdown-item>
                  <b-dropdown-item  @click="$bvModal.show('bv-modal-move-group')">
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Mover de grupo</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="$bvModal.show('bv-modal-massive')">
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Carga Masiva con Excel</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Descargar listado</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item>
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50" style="color:red;">Eliminar</span>
                  </b-dropdown-item>


        </b-dropdown>
      </b-col>
    </b-row>

     <!-- Filters -->
    <users-list-filters
      :search-query.sync="searchQuery"
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"      
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />

    <b-row class="match-height">
        <b-col
            lg="3"
            md="4"
            v-for="dreamer in dreamers"
            v-bind:key="dreamer.id"
        >
            <b-card
                no-body
                class="card-developer-meetup"
            >
                <div class="bg-light-primary rounded-top text-center">
                <b-img
                    :src="dreamer.img"
                    alt="Meeting Pic"
                    style="width:50%"
                />
                </div>
                <b-card-body>
                <!-- metting header -->
                <div class="meetup-header d-flex align-items-center justify-content-between" style="width: 100%; margin-bottom: 0px;">
                  <div class="">
                    <b-form-checkbox
                      :checked="false"
                    />
                  </div>
                  <b-card-title 
                  class="mb-25">
                    <b-link
                      :to="{ name: 'dreamer-view', params: { id: dreamer.id } }"
                      class="font-weight-bold "
                    >
                      {{ dreamer.name }}
                    </b-link>
                  </b-card-title>
                
                  <b-nav-item-dropdown
                      id="my-nav-dropdown"
                      text=""
                      toggle-class="nav-link-custom"
                      right
                      >
                      <b-dropdown-item @click="isAddNewDreamerSidebarActive = true">Editar</b-dropdown-item>
                      <b-dropdown-item @click="$bvModal.show('bv-modal-personalize')">Personalizar</b-dropdown-item>
                      <b-dropdown-item @click="$bvModal.show('bv-modal-content-restriction')">Restringir Contenido</b-dropdown-item>
                      <b-dropdown-item @click="isAddNewDreamerSidebarActive = true">Gestionar Contraseña</b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item
                      ><span style="color:red;">Eliminar</span></b-dropdown-item>
                  </b-nav-item-dropdown>
                    
                </div>
                <!--/ metting header -->

                <!-- avatar group -->
                <b-avatar-group
                    class="mt-2 pt-50 mb-1"
                    size="33"
                >
                    <b-avatar
                    v-for="group in dreamer.groups"
                    :key="group.id"
                    v-b-tooltip.hover.bottom="group.name"
                    :src="group.img"
                    class="pull-up"
                    />
                    <!-- <h6 class="align-self-center cursor-pointer ml-1 mb-0">
                    +2 grupos
                    </h6> -->
                </b-avatar-group>

                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faAlignLeft } from '@fortawesome/free-solid-svg-icons'
library.add(faSpinner, faAlignLeft)

import UsersListFilters from './UsersListFilters.vue'
import UserListAddNewDreamer from './UserListAddNew.vue'

import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
  BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
  BDropdown, BDropdownItem, BDropdownDivider,
  BNav, BNavItem, BNavItemDropdown,
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BBreadcrumb, BBreadcrumbItem, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModalPersonalize from '../modals/ModalPersonalize.vue'
import ModalContentRestriction from '../modals/ModalContentRestriction.vue'
import ModalChildrenPasswords from '../modals/ModalChildrenPasswords.vue'
import ModalMoveGroup from '../modals/ModalMoveGroup.vue'
import ModalMassive from '../modals/ModalMassive.vue'

export default {
  components: {
    UsersListFilters,
    UserListAddNewDreamer, 
    ModalPersonalize,
    ModalContentRestriction,
    ModalChildrenPasswords,
    ModalMoveGroup,
    ModalMassive,

    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
    BDropdown, BDropdownItem, BDropdownDivider,
    BNav, BNavItem, BNavItemDropdown,
    BFormCheckbox,
    BFormGroup, BFormInput, BForm, BFormRadio,
    BBreadcrumb, BBreadcrumbItem, 
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isAddNewDreamerSidebarActive: false, 

      searchQuery: null,
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      roleOptions : [
        { label: 'Seleccione...', value: null },
        { label: '1º A Primaria', value: '1APrimaria' },
        { label: '1º B Primaria', value: 'author' },
        { label: '2º A Primaria', value: 'editor' },
        { label: '3º A Primaria', value: 'maintainer' },
        { label: 'NEE', value: 'subscriber' },
      ],
      planOptions : [
        { label: 'Seleccione...', value: null },
        { label: '0-5', value: 'basic' },
        { label: '6-7', value: 'company' },
        { label: '8-9', value: 'enterprise' },
        { label: '10-12', value: 'team' },
      ],
      statusOptions : [
        { label: 'Seleccione...', value: null },
        { label: 'TEA', value: 'pending' },
        { label: 'TDAH', value: 'active' },
        { label: 'Discapacidad Auditiva', value: 'inactive' },
      ],
      dreamers : [
          {
              id:1,
              name: "Liliana María Alicia",
              img: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
              groups : [
                  {
                      id:1,
                      name: "1º Primaria A",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/7.png',
                  },
                  {
                      id:2,
                      name: "NEE",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/5.png',
                  },
                  {
                      id:3,
                      name: "Inglés Especial",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/4.png',
                  },
              ],
          },
          {
              id:2,
              name: "Ana",
              img: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_50.png?v3',
              groups : [
                  {
                      id:1,
                      name: "1º Primaria A",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/1.png',
                  },
                  {
                      id:2,
                      name: "NEE",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/2.png',
                  },
              ],
          },
          {
              id:3,
              name: "Ana",
              img: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
              groups : [
                  {
                      id:1,
                      name: "2º Primaria A",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/6.png',
                  },
                  {
                      id:3,
                      name: "Inglés Avanzado",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/4.png',
                  },
              ],
          },
          {
              id:4,
              name: "Ana",
              img: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_31.png?v3',
              groups : [
                  {
                      id:1,
                      name: "3º Primaria A",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/7.png',
                  },
                  {
                      id:2,
                      name: "NEE",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/5.png',
                  },
              ],
          },
          {
              id:5,
              name: "Ana",
              img: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
              groups : [
                  {
                      id:1,
                      name: "4º Primaria A",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/7.png',
                  },
              ],
          },
          {
              id:6,
              name: "Ana",
              img: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_11.png?v3',
              groups : [
                  {
                      id:1,
                      name: "4º Primaria A",
                      img: 'https://library.smileandlearn.net/resources/img/avatar/groups/7.png',
                  },
              ],
          },
      ],
      Selected: false,
    }
  },
  methods:{
    refetchData(){
      console.log("recargar data")
    }
  }
}
</script>

<style>
#my-nav-dropdown{
    list-style-type:none;
}
</style>