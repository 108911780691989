<template>
  <b-modal id="bv-modal-move-group" hide-footer>
      <template #modal-title>
        Mover de grupo
      </template>
      <div class="d-block text-center">
        <b-form @submit.prevent>
            <p>¿A dónde quieres mover los dreamers seleccionados?</p>
            <b-row>
                <b-col cols="12">
                    <div>
                        <b-form-group
                        :label="$t('fields.group')"
                        >
                        <b-form-select
                        v-model="group_selected"
                        :options="group_options"
                        />
                        
                    </b-form-group>

                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="button"
                        variant="primary"
                        class="mr-1"
                    >{{ $t('forms.move') }}
                    </b-button>
                    <b-button
                        @click="$bvModal.hide('bv-modal-move-group')"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="button"
                        variant="outline-secondary"
                    >{{ $t('forms.cancel') }}
                    </b-button>
                </b-col>
            </b-row>


      </b-form>
      </div>
    </b-modal>
</template>

<script>
import {
  BCard, BCardText, BTable,
  BFormDatepicker,
  BButton, BRow, BCol, 
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BAvatar, BAvatarGroup, VBTooltip,
  BFormSelect,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        flatPickr, 
        BCard, BCardText, 
        BFormDatepicker,
        BTable,
        BButton, BRow, BCol, 
        BFormCheckbox,
        BFormGroup, BFormInput, BForm, BFormRadio,
        BAvatar, BAvatarGroup,VBTooltip,
        BFormSelect,
        },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data(){
      return {
        Selected: false,
        fecha: false,
        f_from: null,
        f_to: null,
        h_from: null,
        h_to: null,
        days: [],
        restrictions_items: [
            { id: 1, fecha: '2/11/2021 - 7/11/2021', hora: '00:05 - 15:30', dias: 'Ma, Mi, Ju' , dreamers: [ 
                {
                    id:1,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:2,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:3,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                },
                {
                    id:4,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:5,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:6,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                },
                {
                    id:7,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:8,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:9,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                }
            ]},
            { id: 2, fecha: '22/01/2021 - 17/02/2021', hora: '10:15 - 18:30', dias: 'Lu, Mi, Vi' , dreamers: [ 
                {
                    id:2,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:3,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                }
            ]},
            { id: 3, fecha: '20/12/2021 - 20/12/2022', hora: '09:05 - 18:00', dias: 'Lu, Ma, Mi, Ju, Vi' , dreamers: [ {
                id:3,
                name:'María', 
                avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
            }]},
        ],
        restrictions_fields: [
            { key: 'restriccion', label: 'Restricción' },
            { key: 'dreamers', label: 'Niños' },
            'acciones',
        ],
        group_selected: null,
        group_options: [
            { value: null, text: 'Please select an option' },
            { value: '1ºA', text: '1ºA' },
            { value: '1ºB', text: '1ºB' },
            { value: '2ºA', text: '2ºA' },
            { value: '2ºB', text: '2ºB' },
            { value: '3ºA', text: '3ºA' },
            { value: '3ºB', text: '3ºB' },
        ],
      }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

</style>