<template>
  <dreamers-list />
</template>

<script>
import DreamersList from '@/views/schools/dreamers/List.vue'
export default {
  components:{
    DreamersList
  }
}
</script>

<style>

</style>