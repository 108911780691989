<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-tabs justified pills>
          <b-tab
            title="Cargar documento excel"
            :active="tabIndex == 0"
            :disabled="isDisabled[0]"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
              <b-card-text>
                Wizard para crear/editar registros de Teachers, Students, Dreamers & Groups
              </b-card-text>
                <h5>Indicaciones</h5>
                <b-card-text class="text-muted">
                  <ol>
                    <li>
Descargue la 
                  <b-link
                    href="https://backend.dev.smileandlearn.net/data/BulkImportUserExample.xlsx"
                    target="_blank"
                  >plantilla de ejemplo</b-link>, 
                  o exporte los
                  <b-link
                    href="https://backend.dev.smileandlearn.net/data/BulkImportUserExample.xlsx"
                    target="_blank"
                  >datos actuales</b-link>
                    </li>
                    <li>Complete cada fila con los datos correspondientes</li>
                    <li>Guarde los cambios</li>
                    <li>Seleccione el archivo haciendo click en el cuadro aquí debajo</li>
                  </ol>
                 
                </b-card-text>
                <b-card-text class="text-muted"> El proceso de carga puede tardar unos minutos.</b-card-text>
              </b-col>
              <b-col md="12">
                <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                  <div class="dropbox">
                    <input type="file"  
                      name="bulkexcel"
                      :disabled="isSaving" 
                      @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                      class="input-file">
                        <p v-if="isInitial">
                          Drag your document here to begin<br> or click to browse
                        </p>
                        <p v-if="isSaving">
                          Uploading {{ fileCount }} files...
                        </p>
                  </div>
                
                </form>

                <!--SUCCESS-->
                <div v-if="isSuccess">
                  <h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
                  <p>
                    <a href="javascript:void(0)" @click="reset()">Upload again</a>
                  </p>
                  <ul class="list-unstyled">
                    <li v-for="item in uploadedFiles" :key="item.url">
                      <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
                    </li>
                  </ul>
                </div>

                <!--FAILED-->
                <div v-if="isFailed">
                  <h2>Uploaded failed.</h2>
                  <p>
                    <a href="javascript:void(0)" @click="reset()">Try again</a>
                  </p>
                  <pre>{{ uploadError }}</pre>
                </div>
                
                
              </b-col>
            </b-row>
              <!-- BUTTONS -->
              <div style="text-align:center;">
                <b-button
                  variant="outline-secondary"
                  class="mt-1 mr-1"
                  type="button"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="$emit('close')"
                >
                  Cancelar
                </b-button>
                <b-button
                  variant="primary"
                  class="mt-1"
                  type="button"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="goNext"
                  :disabled="!isSuccess"
                >
                  Continuar
                </b-button>
              </div>
          </b-tab>
          <b-tab 
            title="Verificar Información"
            :active="tabIndex == 1"
            :disabled="isDisabled[1]"
          >
            <b-card-text>
              En la tabla siguiente corrobore que los datos introducidos son correctos.
            </b-card-text>
            <b-table
              sticky-header="true"
              :no-border-collapse="true"
              striped
              :items="licences"
              :fields="tableColumns"
              responsive
            >

            </b-table>

            <h5 class="mb-1">
              ¿La información es correcta?
            </h5>
            <b-form-checkbox
              v-model="verified"
              plain
            >
              Sí, es correcta
            </b-form-checkbox>
            <p class="text-muted">Si la información es incorrecta, regrese al paso anterior, modifique el documento y vuelva a subirlo.</p>
              <!-- BUTTONS -->
              <div style="text-align:center;">
                <b-button
                  variant="outline-secondary"
                  class="mt-1 mr-1"
                  type="button"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="tabIndex = 0"
                >
                  Volver
                </b-button>
                <b-button
                  variant="primary"
                  class="mt-1"
                  type="button"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="confirmData"
                  :disabled="!verified"
                >
                  Confirmar
                </b-button>
              </div>
          </b-tab>
          <b-tab
            title="Confirmación"
            :active="tabIndex == 2"
            :disabled="isDisabled[2]"
          >
            <b-card-text>
              Se han realizado las siguientes modificaciónes en la Base de Datos de Profesores del colegio.
            </b-card-text>
            <b-table
                  sticky-header="true"
                  :no-border-collapse="true"
                  striped
                  :items="licences"
                  :fields="tableColumnsWithStatus"
                  responsive
                >
                <template #cell(status)="data">
                 <b-badge :variant="data.item.status == 'created' ?'success':'warning'">
                  {{data.item.status.toUpperCase()}}
                </b-badge>
                </template>
                </b-table>
                <div style="text-align:center;">
                <b-button
                  variant="outline-secondary"
                  class="mt-1 mr-1"
                  type="button"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="$emit('close')"
                >
                  Finalizar
                </b-button>
                </div>
          </b-tab>
        </b-tabs>

        
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BButton,
  BFormCheckbox,
  BTable,
  BBadge,
  BTabs, BTab,
  BCardText,
  BCard
} from 'bootstrap-vue'

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;


export default {
components: {
  BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BLink,
    BButton,
    BFormCheckbox,
    BTable,
    BBadge,
    BCardText,
    BTabs, BTab,
  },
  props:{
    school_id:{
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      //navigation
      isDisabled: [false, true, true],
      tabIndex: 0,
      
      //upload file
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',

      //info checked
      verified: false,
      licences: [
        {
          name: "Juan",
          email: "juan@demo.com",
          pass: "1234qwer",
          valid_from: "21/02/2021",
          valid_until: "21/02/2022",
          status: "created"
        },
        {
          name: "Maria",
          email: "maria@demo.com",
          pass: "1234qwer",
          valid_from: "21/02/2021",
          valid_until: "21/02/2022",
          status: "created"
        },
        {
          name: "Susana",
          email: "susana@demo.com",
          pass: "1234qwer",
          valid_from: "21/02/2021",
          valid_until: "21/02/2022",
          status: "modified"
        },
        {
          name: "Pedro",
          email: "pedro@demo.com",
          pass: "1234qwer",
          valid_from: "21/02/2021",
          valid_until: "21/02/2022",
          status: "created"
        },
        {
          name: "Ana",
          email: "ana@demo.com",
          pass: "1234qwer",
          valid_from: "21/02/2021",
          valid_until: "21/02/2022",
          status: "created"
        },
      ],
      tableColumns : [
          { label: "Nombre", key: 'name'},
          { label: "Email", key: 'email' },
          { label: "Password", key: 'pass' },
          { label: "Valid From", key: 'valid_from' },
          { label: "Valid Until", key: 'valid_until' },
      ],
      tableColumnsWithStatus: [
          { label: "Nombre", key: 'name'},
          { label: "Email", key: 'email' },
          { label: "Password", key: 'pass' },
          { label: "Valid From", key: 'valid_from' },
          { label: "Valid Until", key: 'valid_until' },
          { label: "Status", key: 'status' }
      ],
    }
  },
  watch:{
    tabIndex(){
      if(this.tabIndex==2){
        this.isDisabled = [true, true, false]
      }
    },
  },
  computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
  },
  methods: {
    //nav
    goNext(){
      this.isDisabled = [false, false, true]
       console.log(this.tabIndex)
       this.tabIndex++
       console.log(this.tabIndex)
    },
    confirmData(){
      this.goNext()
      console.log('enviar json a backend para guardar info, y recibir json con status de cambios')
    },

    //upload file
    reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
    },
    async save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      await this.$http.post('bulk/import/wizard', formData)
        .then(x => x.data)
        .then(x => {
          console.log(x, "--- response bulk/import/wizard")
          this.uploadedFiles = [].concat(x);
          this.currentStatus = STATUS_SUCCESS;
          this.$emit('refetch-data');
        })
        .catch(err => {
          console.log("ERR", err.response)
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });


        /* BORRAR DESPUES... */
        //this.uploadedFiles = [].concat(x);
          /* console.log(this.uploadedFiles)
          this.currentStatus = STATUS_SUCCESS;
          this.$emit('refetch-data'); */
        /* HASTA ACA */
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();
      
      //formData.append('context', this.context); // licences
      formData.append('school_id', this.school_id); // licences

      if (!fileList.length) return;

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });

      // save it
      this.save(formData);
    },
  },
  mounted() {
    this.reset();
  },
}
</script>

<style>

  .dropbox {
    outline: 2px dashed #87bced; /* the dash box */
    outline-offset: -10px;
    background: #87bced30;
    color: #87bced;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: #87bced70; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
