<template>
  <b-modal id="bv-modal-children-passwords" hide-footer>
      <template #modal-title>
        Gestionar Contraseñas
      </template>
      <div class="d-block text-center">
        <b-form @submit.prevent>
          <h5>Acceso con Contraseña</h5>
          <p>Para acceder a su perfil dentro de Smile and Learn, cada niño deberá introducir su contraseña</p>
            <b-row>
                <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="button"
                        variant="primary"
                        class="mr-1"
                    >Crear claves para todos los niños
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="button"
                        variant="outline-secondary"
                    >Quitar todas las claves
                    </b-button>
                </b-col>
            </b-row>


      </b-form>
      </div>
    </b-modal>
</template>

<script>
import {
  BCard, BCardText, BTable,
  BFormDatepicker,
  BButton, BRow, BCol, 
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BAvatar, BAvatarGroup, VBTooltip
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        flatPickr, 
        BCard, BCardText, 
        BFormDatepicker,
        BTable,
        BButton, BRow, BCol, 
        BFormCheckbox,
        BFormGroup, BFormInput, BForm, BFormRadio,
        BAvatar, BAvatarGroup,VBTooltip,
        },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data(){
      return {
          Selected: false,
          fecha: false,
          f_from: null,
          f_to: null,
          h_from: null,
          h_to: null,
          days: [],
          restrictions_items: [
            { id: 1, fecha: '2/11/2021 - 7/11/2021', hora: '00:05 - 15:30', dias: 'Ma, Mi, Ju' , dreamers: [ 
                {
                    id:1,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:2,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:3,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                },
                {
                    id:4,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:5,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:6,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                },
                {
                    id:7,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:8,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:9,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                }
            ]},
            { id: 2, fecha: '22/01/2021 - 17/02/2021', hora: '10:15 - 18:30', dias: 'Lu, Mi, Vi' , dreamers: [ 
                {
                    id:2,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:3,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                }
            ]},
            { id: 3, fecha: '20/12/2021 - 20/12/2022', hora: '09:05 - 18:00', dias: 'Lu, Ma, Mi, Ju, Vi' , dreamers: [ {
                id:3,
                name:'María', 
                avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
            }]},
        ],
        restrictions_fields: [
            { key: 'restriccion', label: 'Restricción' },
            { key: 'dreamers', label: 'Niños' },
            'acciones',
        ],
      }
  }
}
</script>

<style lang="scss" scoped>

</style>