<template>
  <b-modal id="bv-modal-personalize" hide-footer>
      <template #modal-title>
        Personalizar
      </template>
      <div class="d-block text-center">
        <b-form @submit.prevent>
          <div class="row">
            <div class="col-12">
              <div class="customization-header">
                Nivel de Dificultad
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom:14px;">
            
            <div class="col-sm-3 d-none d-md-block"><br> 
              <div class="avatar-container">
                <img src="https://library.smileandlearn.net/resources/img/avatar/groups/multi.png" class="img img-responsive" style="height: 140px;border-radius: 20px;">
              </div>
            </div> 
            
            <div class="col-sm-5">
              <div class="row">
                <div class="col-sm-6 col-xs-6">
                  <div class="demo-inline-spacing">
                    <b-form-radio
                        v-model="dificultad"
                        name="dificultad-radios"
                        value="Aprendiz"
                    >
                        Aprendiz
                    </b-form-radio>
                    <b-form-radio
                        v-model="dificultad"
                        name="dificultad-radios"
                        value="Intermedio"
                    >
                        Intermedio
                    </b-form-radio>
                  </div>
                </div> 

                <div class="col-sm-6 col-xs-6">
                  <div class="demo-inline-spacing">
                    <b-form-radio
                        v-model="dificultad"
                        name="dificultad-radios"
                        value="Avanzado"
                    >
                        Avanzado
                    </b-form-radio>
                    <b-form-radio
                        v-model="dificultad"
                        name="dificultad-radios"
                        value="Experto"
                    >
                        Experto
                    </b-form-radio>
                  </div>
                </div> 
              </div>
            </div> 
                  
            <div class="col-sm-4 text-center switch-padding">
              <div style="padding-top: 1.5rem;">
                  <b-form-checkbox
                      :checked="false"
                      switch
                      /> Modo Tranquilo
              </div>
            </div>
            
          </div>

          <div class="row">
            <div class="col-12">
              <div class="customization-header">
                Modo de Lectura
              </div>
            </div>
          </div>

        <b-row>
          <b-col cols="12">
            <b-form-group>
              <div class="demo-inline-spacing">
                  <b-form-radio
                      v-model="lectura"
                      name="lectura-radios"
                      value="Leer"
                  >
                      Leer
                  </b-form-radio>
                  <b-form-radio
                      v-model="lectura"
                      name="lectura-radios"
                      value="Escuchar"
                  >
                      Escuchar
                  </b-form-radio>
                  <b-form-radio
                      v-model="lectura"
                      name="lectura-radios"
                      value="Leer y escuchar"
                  >
                      Leer y escuchar
                  </b-form-radio>
                  <b-form-radio
                      v-model="lectura"
                      name="lectura-radios"
                      value="Pictogramas"
                  >
                      Pictogramas
                  </b-form-radio>
              </div>

            </b-form-group>
          </b-col>

        </b-row>
        
        <div class="row">
            <div class="col-12">
              <div class="customization-header">
                Tipo de Letra
              </div>
            </div>
          </div>

        <b-row>
          <b-col cols="12">   
            <b-form-group>
              <div class="demo-inline-spacing">
                  <b-form-radio
                      v-model="letra"
                      name="letra-radios"
                      value="Estándar"
                  >
                      Estándar
                  </b-form-radio>
                  <b-form-radio
                      v-model="letra"
                      name="letra-radios"
                      value="Enlazada"
                  >
                      Enlazada
                  </b-form-radio>
                  <b-form-radio
                      v-model="letra"
                      name="letra-radios"
                      value="MAYÚSCULA"
                  >
                      MAYÚSCULA
                  </b-form-radio>
              </div>


            </b-form-group>
          </b-col>
        </b-row>

        <div class="row">
          <div class="col-12">
            <div class="customization-header">
              Necesidades Especiales
            </div>
          </div>
        </div>

        <b-row>
          <b-col cols="12">
            <b-form-group>
            
              <div class="row">
                  <div class="demo-inline-spacing">
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="TDAH"
                    >
                        TDAH
                    </b-form-radio>
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="TEA"
                    >
                        TEA
                    </b-form-radio>
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="Discapacidad intelecual"
                    >
                        Discapacidad intelecual
                    </b-form-radio>
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="Discapacidad motora"
                    >
                        Discapacidad motora
                    </b-form-radio>
                  
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="Discapacidad Auditiva"
                    >
                        Discapacidad Auditiva
                    </b-form-radio>
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="Discapacidad visual"
                    >
                        Discapacidad visual
                    </b-form-radio>
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="TEL"
                    >
                        TEL
                    </b-form-radio>
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="Retraso del lenguaje"
                    >
                        Retraso del lenguaje
                    </b-form-radio>
                      <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="AACI"
                    >
                        AACI
                    </b-form-radio>
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="AITSE"
                    >
                        AITSE
                    </b-form-radio>
                      <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="ADEA"
                    >
                        ADEA
                    </b-form-radio>
                    <b-form-radio
                        v-model="nee"
                        name="nee-radios"
                        value="Otros"
                    >
                        Otros
                    </b-form-radio>
                  </div>
                
              </div>

            </b-form-group>
          </b-col>
        </b-row>

        <div class="row">
          <div class="col-12">
            <div class="customization-header">
              Idiomas
            </div>
          </div>
        </div>

        <b-row>
          <b-col cols="12">
            <b-form-group>
            
              <div class="row">
                  <div class="demo-inline-spacing">
                    <b-form-radio
                        v-model="idiomas"
                        name="idiomas-radios"
                        value="Español"
                    >
                        Español
                    </b-form-radio>
                    <b-form-radio
                        v-model="idiomas"
                        name="idiomas-radios"
                        value="Inglés"
                    >
                        Inglés
                    </b-form-radio>
                    <b-form-radio
                        v-model="idiomas"
                        name="idiomas-radios"
                        value="Fracés"
                    >
                        Fracés
                    </b-form-radio>
                    <b-form-radio
                        v-model="idiomas"
                        name="idiomas-radios"
                        value="Catalán"
                    >
                        Catalán
                    </b-form-radio>
                  
                    <b-form-radio
                        v-model="idiomas"
                        name="idiomas-radios"
                        value="Italiano"
                    >
                        Italiano
                    </b-form-radio>
                    <b-form-radio
                        v-model="idiomas"
                        name="idiomas-radios"
                        value="Portugués"
                    >
                        Portugués
                    </b-form-radio>
                    
                    
                  </div>
                
              </div>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Borrar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      </div>
      <!-- <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close Me</b-button> -->
    </b-modal>
</template>

<script>
import {
  BCard, BCardText, 
  
  BButton, BRow, BCol, 
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BCardText, 
  
  BButton, BRow, BCol, 
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      dificultad: null,
      lectura: null,
      letra: null,
      nee: null,
      idiomas: null,
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';

/* MODAL Personalizar */
.avatar-container {
    z-index: 1;
    position: absolute;
    right: 4em;
    top: -31px;
    height: 140px;
    width: 120px;
    background-color: #09b8dd;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 0px 5px #fff;
    box-shadow: 0px 0px 0px 5px #fff;
}
.customization-header {
    background-color: $primary;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
}
.demo-inline-spacing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
</style>