<template>
  <b-modal id="bv-modal-content-restriction" hide-footer>
      <template #modal-title>
        Content Restriction
      </template>
      <div class="d-block text-center">
        <b-form @submit.prevent>
          <div class="row">
            <div class="col-12">
              <div class="customization-header">
                Restricciones Activas
              </div>
            </div>
          </div>
          

        <b-row>
          <b-col cols="12" >
             <div >
            <b-table small striped hover responsive style="width: auto;
                max-height: 250px;
                white-space: nowrap;"
             :fields="restrictions_fields" 
             :items="restrictions_items">
                <template #cell(restriccion)="data">
                  <div>
                    <div class="font-small-1 font-weight-bolder">
                     {{ data.item.fecha }}
                    </div>
                    <div class="font-small-2 ">
                      {{ data.item.hora }}
                    </div>
                    <div class="font-small-2 text-muted">
                      {{ data.item.dias }}
                    </div>
                  </div>
                </template>
                <template #cell(dreamers)="data">
                  <div style="min-width: 100px;">
                    <b-avatar-group
                        class="mt-2 pt-50 mb-1"
                        size="33"
                    >
                    <template v-if="data.item.dreamers.length < 7">
                        <b-avatar
                          v-for="avatar in data.item.dreamers"
                          :key="avatar.id"
                          v-b-tooltip.hover.bottom="avatar.name"
                          :src="avatar.avatar"
                          class="pull-up"
                        />
                    </template>
                    <template v-else>
                        <b-avatar
                          v-for="avatar in data.item.dreamers.slice(0, 5)"
                          :key="avatar.id"
                          v-b-tooltip.hover.bottom="avatar.name"
                          :src="avatar.avatar"
                          class="pull-up"
                        />
                        <b-avatar variant="primary"
                        v-b-tooltip.hover.bottom="'+'+(data.item.dreamers.length - 5)">
                          <feather-icon icon="UserPlusIcon" />
                        </b-avatar>
                    </template>


                        
                        
                    </b-avatar-group>
                  </div>
                </template>
                <template #cell(acciones)="data">
                    <span>
                      {{data.item.id}}
                        <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-success"
                            class="btn-icon rounded-circle"
                            >
                            <feather-icon icon="Edit3Icon" />
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-danger"
                            class="btn-icon rounded-circle"
                            >
                            <feather-icon icon="Trash2Icon" />
                        </b-button>
                    </span>
                </template>

            </b-table>
            </div>
          </b-col>

        </b-row>
        
        <div class="row">
            <div class="col-12">
              <div class="customization-header">
                Añadir restricción
              </div>
            </div>
        </div>


        <div>
            <h5 class="mt-1">Fecha</h5>
            <p class="mt-1">
              <b-form-checkbox
                checked="false"
                class="custom-control-info"
                name="check-button"
                switch
                v-model="fecha"
                style="display:inline;"
              />
              ¿Limitar las fechas de la restricción? <br><small> Si no se marca esta casilla, la restricción estará activa hasta que se elimine.</small>
            </p>
        </div>

        <b-row v-if="fecha">
          <b-col cols="6">   
            <b-form-group
              label="Desde"
              label-for="example-datepicker"
            >
          
                <b-form-datepicker
                    id="example-datepicker"
                    v-model="f_from"
                    class="mb-1"
                />
            </b-form-group>
          </b-col>
          <b-col cols="6">   
            <b-form-group
              label="hasta"
              label-for="example-datepicker"
            >
          
                <b-form-datepicker
                    id="example-datepicker"
                    v-model="f_to"
                    class="mb-1"
                />
            </b-form-group>
          </b-col>
        </b-row>

        <h5 class="mt-1">Hora</h5>
        <div class="row">

          <!-- time picker -->
            <b-col md="6">
                <b-form-group
                label="Desde"
                >
                <flat-pickr
                    v-model="h_from"
                    class="form-control"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                label="Hasta"
                >
                <flat-pickr
                    v-model="h_to"
                    class="form-control"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                />
                </b-form-group>
            </b-col>
        </div>

        <h5 class="mt-1">Días</h5>
       

        <b-row>
          <b-col cols="12">
            <b-form-group>
            
                  <div class="demo-inline-spacing">
                    <b-form-radio
                        v-model="Selected"
                        name="days-radios"
                        value="Lu"
                    >
                        Lu
                    </b-form-radio>
                    <b-form-radio
                        v-model="Selected"
                        name="days-radios"
                        value="Ma"
                    >
                        Ma
                    </b-form-radio>
                    <b-form-radio
                        v-model="Selected"
                        name="days-radios"
                        value="Mi"
                    >
                        Mi
                    </b-form-radio>
                    <b-form-radio
                        v-model="Selected"
                        name="days-radios"
                        value="Ju"
                    >
                        Ju
                    </b-form-radio>
                  
                    <b-form-radio
                        v-model="Selected"
                        name="days-radios"
                        value="Vi"
                    >
                        Vi
                    </b-form-radio>
                    <b-form-radio
                        v-model="Selected"
                        name="days-radios"
                        value="Sa"
                    >
                        Sa
                    </b-form-radio>
                    <b-form-radio
                        v-model="Selected"
                        name="days-radios"
                        value="Do"
                    >
                        Do
                    </b-form-radio>
                    
                    
                  </div>
                
              

            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit and reset -->
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Borrar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      </div>
      <!-- <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close Me</b-button> -->
    </b-modal>
</template>

<script>
import {
  BCard, BCardText, BTable,
  BFormDatepicker,
  BButton, BRow, BCol, 
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BAvatar, BAvatarGroup, VBTooltip
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        flatPickr, 
        BCard, BCardText, 
        BFormDatepicker,
        BTable,
        BButton, BRow, BCol, 
        BFormCheckbox,
        BFormGroup, BFormInput, BForm, BFormRadio,
        BAvatar, BAvatarGroup,VBTooltip,
        },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data(){
      return {
          Selected: false,
          fecha: false,
          f_from: null,
          f_to: null,
          h_from: null,
          h_to: null,
          days: [],
          restrictions_items: [
            { id: 1, fecha: '2/11/2021 - 7/11/2021', hora: '00:05 - 15:30', dias: 'Ma, Mi, Ju' , dreamers: [ 
                {
                    id:1,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:2,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:3,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                },
                {
                    id:4,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:5,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:6,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                },
                {
                    id:7,
                    name:'Ana',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3',
                },
                {
                    id:8,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:9,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                }
            ]},
            { id: 2, fecha: '22/01/2021 - 17/02/2021', hora: '10:15 - 18:30', dias: 'Lu, Mi, Vi' , dreamers: [ 
                {
                    id:2,
                    name:'Juan',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3',
                },
                {
                    id:3,
                    name:'María',
                    avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
                }
            ]},
            { id: 3, fecha: '20/12/2021 - 20/12/2022', hora: '09:05 - 18:00', dias: 'Lu, Ma, Mi, Ju, Vi' , dreamers: [ {
                id:3,
                name:'María', 
                avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3',
            }]},
        ],
        restrictions_fields: [
            { key: 'restriccion', label: 'Restricción' },
            { key: 'dreamers', label: 'Niños' },
            'acciones',
        ],
      }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/bootstrap-extended/include';

/* MODAL Personalizar */

.customization-header {
    background-color: $primary;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
}
.demo-inline-spacing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
</style>