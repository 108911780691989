<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('forms.add_model', {model: $t('models.dreamer')})}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
   
          <!-- AVATAR -->
          <div
          class=" text-center"
          style="width: 140px; margin: auto;
          background-color: #09b8dd;
          border-radius: 20px;">
            <b-carousel
              id="carousel-example-generic"
              controls
              :interval="0"
              v-model="slide"
            >
              <b-carousel-slide v-for="i in 10" :key="i" :img-src="'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_'+i+'.png?v3'" />

            </b-carousel>
          </div>
<!-- {{ slide }} -->
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Juan"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
              label="Choose a date"
              label-for="example-datepicker"
            >
          
          <b-form-datepicker
            id="example-datepicker"
            v-model="f_nac"
            class="mb-1"
          />
          </b-form-group>


          <div>
            <b-form-group
              label="Group"
            >
            <b-form-select
              v-model="group_selected"
              :options="group_options"
            />
            
          </b-form-group>

          </div>


          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div>
            <p class="mb-1">
              <b-form-checkbox
                checked="false"
                class="custom-control-info"
                name="check-button"
                switch
                v-model="nee"
                style="display:inline;"
              />
              ¿Tiene necesidades educativas especiales?
            </p>
          </div>
          <div v-if="nee">
            <b-form-group>
              
                <b-form-checkbox 
                v-for="option in nee_options_1"
                :value="option"
                v-model="nee_selected"
                name="flavour-2"
                :key="option">
                  {{ option }}
                </b-form-checkbox>
                <b-form-checkbox 
                v-for="option in nee_options_2"
                :value="option"
                v-model="nee_selected"
                name="flavour-2"
                :key="option">
                  {{ option }}
                </b-form-checkbox>
            </b-form-group>
          </div>

          <div>
            <p class="mb-1">
              <b-form-checkbox
                checked="false"
                class="custom-control-info"
                name="check-button"
                switch
                v-model="acc_pass"
                style="display:inline;"
              />
              Acceso con Contraseña
            </p>
          </div>
          <div v-if="acc_pass">
            <div class="row childpassword">
              <div class="col-md-12">
                  <div class="childpassword__container childpassword__container--selector">
                      <template v-for="index in 9">
                          <div :key="index" @click="addPassword(index)"
                                class="childpassword__item childpassword__item--button">
                                <b-img
                                  :src="require('@/assets/images/passwords/' + index + '.png')"
                                />
                          </div>
                      </template>                  
                  </div>
              </div> 
              <div class="col-md-12">
                  <div class="row">
                      <div class="childpassword__container childpassword__container--result">
                        <template v-for="(p, index) in child_password">
                            <div :key="index" 
                                  class="childpassword__item">
                                  <b-img
                                  :src="require('@/assets/images/passwords/' + p + '.png')"
                                  
                                />
                                <span class="order">{{ index + 1 }}</span>
                            </div>
                        </template>
                      </div>
                  </div>
                  <div class="row">
                    <div class=" childpassword__delete">
                      <button @click="emptyPassword" class="btn btn-danger btn-sm">
                              Eliminar
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('forms.add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('forms.cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BCarousel, BCarouselSlide,
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BFormDatepicker,
  BFormCheckbox,
  BFormCheckboxGroup, BCardText,
  BImg,
  BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BFormDatepicker,
    BFormCheckbox,
    BFormCheckboxGroup, BCardText,
    BCarousel, BCarouselSlide,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BImg,
    BFormSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      slide:0,
      f_nac: '',
      nee: false,
      acc_pass:false,
      nee_options_1: ['Trastornos del aprendizaje', 'Discapacidad intelectual',  'Discapacidad motora', 'Discapacidad auditiva', 'Discapacidad visual',  'Retraso del lenguaje'],
      nee_options_2: ['TEA','TEL','AACI','AITSE',' ADEA','Otros'],
      nee_selected:[],
      child_password: [],
      group_selected: null,
      group_options: [
        { value: null, text: 'Please select an option' },
        { value: '1ºA', text: '1ºA' },
        { value: '1ºB', text: '1ºB' },
        { value: '2ºA', text: '2ºA' },
        { value: '2ºB', text: '2ºB' },
        { value: '3ºA', text: '3ºA' },
        { value: '3ºB', text: '3ºB' },
      ],

      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
/* 
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
 */    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    emptyPassword() {
        this.child_password = [];
    },
    addPassword(index) {
        if (this.child_password.length < 3) {
            this.child_password.push(index);
        }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}




/* CHILDPASSWORD */
.childpassword {
    text-align: center;
}
.childpassword__container--selector {
    width: 200px;
    height: 200px;
}
.childpassword__container {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px;
    background-color: #b5eff9;
    border-radius: 8px;
}
.childpassword__item--button {
    cursor: pointer;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
.childpassword__item {
    height: 60px;
    width: 60px;
    background-size: cover;
}
.childpassword__delete{
    width: 200px;
    height: 30px;
    margin: 20px auto 5px auto;
}
.childpassword__container--result {
    width: 200px;
    height: 80px;
    margin: 20px auto 5px auto;
}
.childpassword__item > .order {
    background-color: #68c500;
    color: white;
    display: block;
    border-radius: 50%;
    position: relative;
    top: -64px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
}
</style>
