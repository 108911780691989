<template>
  <div>
    <!-- BREADCRUMBS -->
    <custom-breadcrumb
      :pageTitle="$t('Dreamers')"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>
    
    <!-- group reasign modal -->
    <b-modal
      id="reasign-modal"
      ref="reasign-modal"
      :title="$t('forms.reasign_group')"
      ok-only
      :ok-title="$t('forms.submit')"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOkReasignGroup"

      no-close-on-backdrop
    >
      <b-card-text>
        <h5>{{$t('forms.reasign_group_description')}}</h5>
        {{$t('forms.reasign_group_disclaimer')}}
      </b-card-text>
      <form
        ref="reasignform"
        @submit.stop.prevent="handleReasignGroup"
      >
        <b-form-group
          :state="reasignState"
          :label="$t('fields.group')"
          label-for="group"
          :invalid-feedback="$t('forms.this_field_is_required')"
        >
          <!-- <b-form-input
            id="group"
            v-model="group_to_reasign"
            :state="reasignState"
            required
          /> -->
          <v-select
              v-model="group_to_reasign"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="groups"
              required
            />
        </b-form-group>
      </form>
    </b-modal>
    
    <!-- delete all modal -->
    <b-modal
      id="delete-all"
      ref="delete-all"
      :title="$t('forms.delete_all.dreamers')"
      ok-only
      :ok-title="$t('forms.submit')"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOkDeleteAll"

      no-close-on-backdrop
    >
      <b-card-text>
        <h5>{{$t('forms.delete_all.description')}}</h5>
        {{$t('forms.delete_all.disclaimer')}}
        <p>
          {{$t('forms.delete_all.enter_confirmation_text')}}: <b>{{ stablished_confirmation_text}}</b>
        </p>
      </b-card-text>
      <form
        ref="deleteallform"
        @submit.stop.prevent="handleDeleteAll"
      >
        <b-form-group
          :state="deleteAllState"
          :label="$t('forms.delete_all.confirmation_text')"
          label-for="delete_all"
        >
          <!-- invalid-feedback="Confirmation text is required" -->
          <b-form-input
            id="delete_all"
            v-model="confirmation_text"
            :state="deleteAllState"
            required
          />

          <span class="mt-1" 
           style="color:#ea5455; font-size: 0.857rem; width:100%;"
           v-if="confirmation_text && validate_confirmation_text"
          >{{$t('forms.delete_all.confirmation_text_error')}}</span>
        </b-form-group>
      </form>
    </b-modal>
    
    <!-- ADD DREAMER -->
    <add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :school_id="school_id"
      :groups="groups"
      :users="users"
      @refetch-data="getData"
    />

    <!-- IMPORT WIZARD  -->
    <import-wizard
      v-if="showImportModal"
      @close="showImportModal = false"
      :school_id="$route.params.id"
    ></import-wizard>

    <!-- showDeleteByExcel -->
    <delete-by-excel
      v-if="showDeleteByExcel"
      @close="showDeleteByExcel = false"
      :school_id="school_id"
      @reset-data="getData"
    ></delete-by-excel>
    
    <b-card no-body v-else>
      <!-- FILTROS -->
      <filters
        ref="filter"
        context="dreamers"
        :school_id="school_id"
        :status-options="statusOptions"
        :groups-options="groupsOptions"
        @set-data="setData"
        @set-loading-data="setloadingData"
      /> 

      <!-- TABLA -->
      <paginated-table
        :data="dreamers"
        :canDownload="true"
        ability="SchoolDreamers"
        link='school-dreamers'
        @manage-selected-rows="manageSelectedRows"
        @delete="deleteModel"
        @recover="recoverModel"
        :loading_data="loading_data"
        :school_id="school_id"

        :buttonsOptions="!showImportModal && !showDeleteByExcel ? buttonsOptions: importOptions"
        @close="showImportModal = false; showDeleteByExcel=false"
        @create="isAddNewSidebarActive = true"
        @import="showImportModal = true"
        @reasignGroup="showModalReasignGroup"
        @deleteSelection="deleteSelection"
        @deleteAll="showModalDeleteAll"
        @deleteByExcel="showDeleteByExcel = true"
      ></paginated-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText,
  BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem,

} from 'bootstrap-vue'
import vSelect from 'vue-select'

import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import Filters from '@/views/sl-components/Filters.vue'
import AddNew from './AddNew.vue'
import ImportWizard from '@/views/sl-components/ImportExcelWizard.vue'
import DeleteByExcel from './DeleteByExcel.vue'


export default {
  components:{
    BCard, BRow, BCol,BCardText,
    BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem,
    vSelect,

    CustomBreadcrumb,
    PaginatedTable,
    Filters,
    ImportWizard,
    AddNew,
    DeleteByExcel
  },
  data(){
    return {
      loading_data: false,

      school_id: '',
      showImportModal: false,
      isAddNewSidebarActive: false,
      selected: [],
      dreamers: [],
      groups:[],
      users:[],
      groupsOptions: [],

      group_to_reasign: '',
      reasignState: null,
      confirmation_text:'',
      deleteAllState:null,

      showDeleteByExcel: false,

      school: {}
    }
  },
  computed: {
    breadcrumb(){
      if(this.$store.getters['user/isSchool']){
            this.school_id = this.$store.getters['user/isSchool'].id
            return [
              {
                text: this.$t('Schools'),
              },
              {
                text: this.school.name
              },
              {
                text: this.$t('Dreamers'),
                active: true,
              },
            ]
          } else {
            this.school_id = this.$route.params.id;

            return [
              {
                text: this.$t('Schools'),
                to: 'schools'
              },
              {
                to: 'school-view',
                params: {
                  id: this.$route.params.id,
                },
                text: this.school.name
              },
              {
                text: this.$t('Dreamers'),
                active: true,
              },
            ]
          }
    },
    importOptions(){
      return [
        {
          action: "close",
          icon: "XIcon",
          name: this.$t('import_wizard.go_back_to_list')
        },
      ]
    },
    stablished_confirmation_text(){
      return this.$t('forms.delete_all.stablished_confirmation_text')
    },
    validate_confirmation_text(){
      let valid_text = true
        if(this.confirmation_text == this.stablished_confirmation_text){
          valid_text = false
      }
      this.deleteAllState = !valid_text
      return valid_text
    },
    statusOptions(){
      let role = this.$store.getters['user/getUserData']
      if(role == 'admin' || role == 'support'){
        return [
              { value: 'active', label: this.$t('fields.active') },
              { value: 'inactive', label: this.$t('fields.inactive') },
            ]
      } else {
        return []
      }
    },
    buttonsOptions(){
        let opt = [
          {
            action: "create",
            icon: "PlusIcon",
            variant: "success",
            name: this.$t('forms.add_new')
          },
          
          /* {
            action: "import",
            icon: "UploadIcon",
            name: "Import Dreamers"
          }, */
        ]
        if(this.selected.length > 0){
          console.log(this.selected.length, "this.selected.length")
          opt.push({
            action: "reasignGroup",
            icon: "SmileIcon",
            name: this.$t('forms.reasign_group')
          })
        }
          let del_opt = {
              action: "dropdown",
              icon: "Trash2Icon",
              variant: "danger",
              name: this.$t('forms.delete'),
              dropdown: [
                {
                  action: "deleteByExcel",
                  icon: "FileMinusIcon",
                  name: this.$t('delete_wizard.title'),
                  variant: "danger",
                },
                {
                  action: "deleteAll",
                  icon: "AlertOctagonIcon",
                  name: this.$t('forms.delete_all.dreamers'),
                  variant: "danger",
                }
              ],
            }
          
          if(this.selected.length > 0){
            del_opt.dropdown.unshift({
                  action: "deleteSelection",
                  icon: "Trash2Icon",
                  name: this.$t('table.deactivate_selection', {model: this.$t('models.dreamers')}),
                  variant: "danger",
                })
          }

          opt.push(del_opt)

        return opt
    },
  },
  methods: {

    //delete all
    checkFormValidity() {
      const form_valid = this.$refs.deleteallform.checkValidity()
      console.log(form_valid, "form_valid")

      let valid_text = false
      if(form_valid){
        if(this.confirmation_text == this.stablished_confirmation_text){
          valid_text = true
        }
      }
      this.deleteAllState = valid_text
      return valid_text
    },
    handleOkDeleteAll(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleDeleteAll()
    },
    handleDeleteAll() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      
      let sel = this.dreamers.map(item => item.id)
      let data = {
        dreamers:sel,
      }

      console.log("deleteAllDreamers..", data)
      this.$http.post('school/dreamer/deleteAllDreamers', data).then( response =>{
        console.log(response)
        if(response.data.status == 200){
          this.$refs.filter.search();
          this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));
        } else {
          this.makeToast('danger',this.$t('Error'), this.$t('forms.delete_all.error'));
        }
      }).catch(error => {
        console.log("Err -> ", error)
          this.makeToast('danger',this.$t('Error'), error);
      })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['delete-all'].toggle('#toggle-btn')
      })
    },
    showModalDeleteAll(){
      this.$bvModal.show('delete-all')
    },

    //move group
    checkReasignFormValidity() {
      const valid = this.$refs.reasignform.checkValidity()
      this.reasignState = valid
      return valid
    },
    resetModal() {
      this.group_to_reasign = ''
      this.reasignState = null

      this.confirmation_text = ''
      this.deleteAllState = null
    },
    handleOkReasignGroup(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleReasignGroup()
    },
    handleReasignGroup() {
      // Exit when the form isn't valid
      if (!this.checkReasignFormValidity()) {
        return
      }
      // AXIOS
      let sel = this.selected.map(item => item.id)
      let data = {
        dreamers:sel,
        group_id:this.group_to_reasign.id
      }

      console.log("axios..", data)
      this.$http.post('school/dreamer/reasign', data).then( response =>{
        console.log(response)
        if(response.data.status == 200){
          this.$refs.filter.search();
          this.makeToast('success', this.$t('Success'), this.$t('forms.reasign_group_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('forms.reasign_group_error'));
        }
        this.selected= []
      }).catch(error => {
        console.log("Err -> ", error)
          this.makeToast('danger', this.$t('Error'), error);
      })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['reasign-modal'].toggle('#toggle-btn')
      })
    },
    showModalReasignGroup(){
      this.$bvModal.show('reasign-modal')
      console.log("reasignGroup", this.selected.length)
    },
    
    //
    manageSelectedRows(selected_rows){
      //console.log(selected_rows, "selected_rows")
      this.selected = selected_rows
    },
    deleteSelection(){
        this.$bvModal
          .msgBoxConfirm(this.$t('table.deactivate_dreamers_explanation')+' '+this.$t('table.delete_selection_confirmation', {num:this.selected.length}), {
            title: this.$t('table.delete_selection'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let sel = this.selected.map(item => item.id)
              let data = {
                'dreamers':   sel
              }
              console.log(data)
              this.$http.post('school/dreamer/deleteSelectedDreamers', data).then( response =>{
                console.log(response)
                if(response.data.status == 200){
                  this.$refs.filter.search();

                  this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                }
                this.selected= []

              }).catch(error => {
                console.log("Err -> ", error)
              })
            }
          })
      
    },
    deleteModel(id){
      this.$bvModal
        .msgBoxConfirm(this.$t('table.deactivate_dreamers_explanation')+' '+this.$t('table.deactivate_one_confirmation'), {
          title: this.$t('table.deactivate'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'id': id
              }
              this.$http.post('school/dreamer/delete', data).then( response =>{
                console.log(response)
                if(response.data.status == 200){
                  this.$refs.filter.search();
                  this.makeToast('success', this.$t('Success'), this.$t('table.deactivate_success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.deactivate_error'));
                }
                this.selected = []
              }).catch(error => {
                console.log("Err -> ", error)
              })
            }
          })
    },
    recoverModel(id){
        this.$bvModal
          .msgBoxConfirm(this.$t('forms.recover_confirmation'), {
            title: this.$t('forms.recover'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'dreamer_id': id
              }
              this.$http.post('school/dreamer/recover', data).then( response =>{
                console.log(response)
                if(response.data.status == 200){
                  this.$refs.filter.search();

                  this.makeToast('success', this.$t('Success'), this.$t('forms.recover_success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.recover_error'));
                }

              }).catch(error => {
                console.log("Err -> ", error)
              })
            }
          })
    },
    setloadingData(state){
        this.loading_data = state
    },
    setData(data){
      console.log("set data", data)
        if(data.dreamers.length > 40000){
            this.dreamers = []
            this.fetch_message = this.$t('table.too_many_records')
            this.loading_data = false
            //this.dreamers.length = 0
        } else {
            this.fetch_message = ''
            this.dreamers = data.dreamers;
            this.loading_data = false
            //this.dreamers.length = data.dreamers_length
        }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async getData(){
      this.loading_data = true
        console.log("loading")
        let data = {
          school_id : this.$route.params.id ?? null
        }
        await this.$http.post('school/dreamers',data).then( response =>{
          console.log(response)
          this.dreamers = response.data.dreamers;
          this.groups = response.data.groups;
          this.users = response.data.users;
          this.school = response.data.school
          

          //GROUPS
          let newGroupsOptions = []
          response.data.groups.forEach(x => {
            let can_select = true;
            newGroupsOptions.push(
                { 'value': x.id, 'label': x.name, 'can_select': can_select }
            ) 
          });
          this.groupsOptions = newGroupsOptions;

          this.loading_data = false
          //this.setData(response);
        }).catch(error => {
          console.log("Err -> ", error)
      })
    },
  },
  mounted(){
        this.getData()
  }
}
</script>

<style>

</style>