<template>
  <div>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5>{{ $t('delete_wizard.title')}}</h5>
                <b-card-text class="text-muted">
                  <ol>
                    <li>
                      {{ $t('delete_wizard.step1')}}
                      <b-link
                        href="https://resources.smileandlearn.net/Downloads/Excels/BulkDeleteLicenseExample.xlsx"
                        target="_blank"
                      >{{ $t('delete_wizard.here')}}</b-link>
                    </li>
                    <li>{{ $t('delete_wizard.step2')}}</li>
                    <li>{{ $t('delete_wizard.step3')}}</li>
                    <li>{{ $t('delete_wizard.step4')}}</li>
                  </ol>
                 
                </b-card-text>
                <b-card-text class="text-muted"> {{ $t('delete_wizard.wait')}}</b-card-text>
              </b-col>
              <b-col md="12">
                <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                   <div :class="[show_error ?'dropbox-hasnt-file':isSaving ?'dropbox-is-saving':doc_eliminacion ?'dropbox-has-file' : 'dropbox' ]">
                    <input type="file"  
                      name="licensesFile"
                      :disabled="isSaving" 
                      @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      class="input-file">
                        <p v-if="isInitial && !doc_eliminacion"
                        v-html="
                          $t('delete_wizard.delete_wizard_description')
                        "></p>
                        <p v-if="isSaving">
                          {{ $t('raices.uploading_document') }}...
                          <b-spinner variant="primary" label="Text Centered" />
                        </p>
                        <p v-else-if="doc_eliminacion">
                          {{ $t('raices.selected_document') }} <br>
                          <strong>{{ doc_eliminacion}}</strong>
                          
                        </p>
                  </div>
                
                </form>

                <!--SUCCESS-->
                <div v-if="isSuccess">
                  <b-alert
                    variant="secondary"
                    show
                  >
                    <h4 class="alert-heading">
                      {{ $t('raices.end_process') }}
                    </h4>
                    <div class="alert-body" >
                      <p v-html="$t('forms.delete_all.success')"></p>
                    </div>
                  </b-alert>
                </div>

                <!--FAILED-->
                <div v-if="isFailed">
                  <b-alert
                    variant="danger"
                    show
                  >
                    <h4 class="alert-heading">
                      {{$t('delete_wizard.upload_fail')}}
                    </h4>
                    <div class="alert-body" >
                    <a href="javascript:void(0)" @click="reset()">{{$t('error_retry_later')}}</a>
                    </div>
                  </b-alert>
                </div>
                
                
              </b-col>
            </b-row>
              <!-- BUTTONS -->
              <div style="text-align:center;">
                <b-button
                  variant="outline-secondary"
                  class="mt-1 mr-1"
                  type="button"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="$emit('close')"
                >
                  {{$t('import_wizard.go_back_to_list')}}
                </b-button>
                <b-button
                  variant="danger"
                  class="mt-1"
                  type="button"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="save()"
                  :disabled="canDelete"
                >
                   {{$t('forms.delete')}}
                </b-button>
              </div>
               </b-card>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BButton,
  BFormCheckbox,
  BTable,
  BBadge,
  BTabs, BTab,
  BCardText,
  BCard,
  BSpinner
} from 'bootstrap-vue'

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
components: {
  BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BLink,
    BButton,
    BFormCheckbox,
    BTable,
    BBadge,
    BCardText,
    BTabs, BTab,
    BSpinner
  },
  props:{
    school_id:{
      type: [String, Number],
      required: true,
    },
  },
  data(){
    return {
      doc_eliminacion: '',
      loading_inicial_data: true,
      process_finished: false,
      process_not_allowed: false,


      //upload file
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',

      myFormData: null,

      show_error:false,
    }

  },
  mounted(){
        this.reset();
        const formData = new FormData();
        this.myFormData =formData;
  },
   computed: {
      canDelete(){
        return this.currentStatus === STATUS_SUCCESS || this.doc_eliminacion == ''
      },
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
  },
  methods:{
    //upload file
    reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;

        this.doc_eliminacion = '';

    },
    validate(){
      let has_errors = false
      if(!this.myFormData.has('licensesFile')){
        this.show_error = true
        has_errors = true
      }
      if(!this.myFormData.has('educaFile')){
        this.show_educa_error = true
        has_errors = true
      }
      console.log(has_errors)
      return !has_errors
    },
    filesChange(fieldName, fileList) {
      let thisFormData = this.myFormData
      if (!fileList.length) return;

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          thisFormData.append(fieldName, fileList[x], fileList[x].name);
          if(fieldName == 'licensesFile'){
            this.doc_eliminacion = fileList[x].name
            if(fileList[x].type != "text/csv" && fileList[x].type != "application/vnd.ms-excel" && fileList[x].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
              this.makeToast('danger', this.$t('Error'), this.$t('raices.incorrect_doc_type'));
              this.reset()
            }
            this.show_error = false
          }
        });

      // save it
      this.myFormData = thisFormData;
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    formSubmitted() {
      this.process_finished = true
      //this.$router.push('/')
    },
    save(){
      
      if(this.doc_eliminacion=="") {
        this.makeToast('danger', this.$t('Error'), this.$t('raices.select_doc'));
        return
      }
      this.loading_data = true
      this.currentStatus = STATUS_SAVING
      this.makeToast('warning', this.$t('raices.processing'), this.$t('raices.processing_description'));

      this.$http.post('bulk/deleteLicences', this.myFormData).then(response => {
        console.log(response.data)
        if(response.data.status == 200){
            this.loading_data = false
            this.currentStatus = STATUS_INITIAL;
            this.doc_eliminacion = '';
            const formData = new FormData();
            this.myFormData =formData;
            this.makeToast('primary', this.$t('Success'), response.data.msg );
            this.$emit('reset-data')
            this.reset()

          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg );
            this.reset()
          }
      }).catch(err => {
          console.log("Err -> ", err)
          this.reset()
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      });
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';

  .dropbox {
    outline: 2px dashed #87bced; /* the dash box */
    outline-offset: -10px;
    background: #87bced30;
    color: #87bced;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .dropbox-has-file {
    outline: 2px dashed #28c76f; /* the dash box */
    outline-offset: -10px;
    background: #e4ffc4;
    color: #28c76f;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  .dropbox-hasnt-file {
    outline: 2px dashed #c73328; /* the dash box */
    outline-offset: -10px;
    background: #ffd8c4;
    color: #c73328;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  .dropbox-is-saving{
    outline: 2px dashed gray; /* the dash box */
    outline-offset: -10px;
    background: lightgray;
    color: gray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: #87bced70; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox-is-saving p,
  .dropbox-hasnt-file p,
  .dropbox-has-file p,
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
